import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

import ptYellow from '../images/pt_yellow.gif'
import avatar from '../images/david.transom.jpg'

const AboutPage = () =>
  <Layout>
    <Helmet title="Hexy Music &gt; About" />

    <main id="content">
      <h2>&raquo; About</h2>

      <h3><img src={ ptYellow } alt="" /> This Website</h3>
      <p>HexyMusic is the personal music website of Dave Transom (AD1978); A New
      Zealander who has written and recorded original demo's of typically rock
      based contemporary music.</p>
      <p>This site is a tool for the organisation of that music and other original
      music projects and gives a central point to download their mp3 samples and demos.</p>
      <p>All tracks on this site are amateur recordings from jam sessions and garage
      based multi-track recordings.</p>

      <h3><img src={ ptYellow } alt="" /> Dave Transom</h3>
      <p><img src={ avatar } alt="Artist and site owner, David Transom" width="150" style={{float: "right", border: "solid 1px #000"}} />
      If you feel the urge, you can find out more about me at my main website,
      livedonkey dot net. The site also has a range of FREE goodies for download
      including a variety of scripts used to make this site: <a href="http://livedonkey.net/" rel="external">livedonkey.net</a></p>

      <h3><img src={ ptYellow } alt="" /> The Equipment</h3>
      <p>Below is some of the equipment used in the recordings:</p>
      <ul>
        <li>Ibanez RG570 Electric Guitar (with EMG pickups)</li>
        <li>Ibanez SoundGear Bass</li>
        <li>Gibson Les Paul</li>
        <li>Gibson Electric Acoustic</li>
        <li>Fender Acoustic</li>
        <li>Martin &amp; Co. DX1 Dreadnaught Acoustic</li>
        <li className="orange">Marshall Amplification</li>
        <li className="orange">Mesa/Boogie</li>
        <li className="orange">Boss</li>
        <li className="brown">Tama</li>
        <li className="brown">Zildjin</li>
        <li className="green">Fostex</li>
      </ul>

      <h3><img src={ ptYellow } alt="" /> Privacy</h3>
      <p>This site uses cookies to enhance your experience while visiting. These
      cookies are only available from your personal computer. Information submitted
      to this site, via its various forms etc, will not be given to any third parties
      or used in any way that is detrimental.</p>
    </main>

    <aside id="ear">
      &raquo;
    </aside>
  </Layout>

export default AboutPage
